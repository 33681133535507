<template>
  <list-container-widget
    id="courses"
    :app-id="18"
    :title="widgetName"
    stretch
    class="d-flex"
  >
    <!-- Panel actions -->
    <template #actions>
      <ViewAllButton item-type="courses" />
    </template>
    <template>
      <!-- Loading -->
      <b-card-body class="x-scroll-container">
        <div v-if="isLoading">
          <b-spinner small class="mx-auto mt-3 d-block" />
        </div>

        <!-- Container with data -->
        <div v-else-if="items && items.length > 0" class="cards-container">
          <div
            ref="container"
            class="x-scroll-container w-100 py-0"
            @scroll="onScroll"
          >
            <!-- Items Loop -->
            <div
              v-for="item in items"
              :key="item.key"
              :class="item.isPublished || (!item.isPublished && canAdd)?'mb-sm-50 x-scroll-element mx-1 mb-3 p-0':'d-none'"
            >
              <course-item
                v-if="item.isPublished || (!item.isPublished && canAdd)"
                :item="item"
              />
            </div>
            <!-- Buttons prev and next -->
            <button
              class="link-previous btn btn-icon btn-primary btn-previous"
              @click="scrollOnePage(-1)"
            >
              <b-spinner small class="spinner" />
            </button>
            <button
              class="link-next btn btn-icon btn-primary btn-next"
              :class="{ 'btn-next--loading': isLoadingNextPage }"
              @click="scrollOnePage(+1)"
            >
              <b-spinner small class="spinner" variant="primary" />
            </button>
          </div>
        </div>

        <b-row v-else class="horizontal-placeholder">
          <b-col cols="12">
            <img :src="eventsPlaceholder" alt="Events with members">
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{ $t("available.message", { itemName: widgetName }) }}
            </p>
          </b-col>
        </b-row>
      </b-card-body>
    </template>

  </list-container-widget>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
// import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
// import { quillEditor } from 'vue-quill-editor';
import moment from 'moment';
import WidgetLayoutMixin from '@core/mixins/widgets/WidgetLayoutMixin';
// import vSelect from 'vue-select';
import { checkPermissions } from '@/@core/utils/roles-utils';
import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';
// import MembershipEventCreateModal from '@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import CourseItem from '../components/CourseItem.vue';

export default {
  name: 'MembershipEventsListWidget',
  components: {
    ListContainerWidget,
    // WidgetActions,
    // quillEditor,
    // vSelect,
    CourseItem,
    // MembershipEventCreateModal,
    LanguageSelectorHeader,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      formInput: {},
      itemImage: null,
      imageSrc: null,
      searchInput: '',
      results: [],
      perPage: 10,
      typeOptions: [],
      isModalVisible: false,
      selectedLanguage: this.currentLocale,

    };
  },

  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    // Load data from store
    itemsData() {
      return this.$store.getters.communitiesOthers.courses;
    },
    items() {
      // return this.itemsData.unpaginated;
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData?.unpaginated;
      return itemsToIterate;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key
        || this.isStaff
        || checkPermissions(
          'create',
          'courses',
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },
    eventsPlaceholder() {
      return EventsPlaceholder;
    },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },

  // Fetch data when component is created!
  async created() {
    await this.fetchData(true);
  },
  methods: {
    getImageSrc(url) {
      return getImageResource(url);
    },
    timestampToTime(timestamp) {
      return moment(timestamp).format('DD-MM-YYYY');
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    closeModal() {
      this.$bvModal.hide('modal-create-courses');
    },

    // Call store action (with dispach) to load data from backend
    async fetchData(force = false) {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'communities/simply',
        customName: 'communitiesOthers',
        storedKey: 'courses',
        page: this.lastLoadedPage,
        perPage: 30,
        forceAPICall: force,
        requestConfig: {
          isCourse: true,
          orderByDate: -1,
          communityParentSlug: this.$store.getters.currentCollective.slug,
          classifiers: this.finalSelectClassifier,
          customFields:
            this.customFieldToSend != null && Object.keys(this.customFieldToSend).length > 0
              ? this.customFieldToSend
              : null,
        },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd = event.srcElement.scrollWidth
        - event.srcElement.scrollLeft
        - event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        // this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length <= total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.itemsData.unpaginated.filter((item) => item.name.toLowerCase().trim().includes(searchTerm));
    },
  },
};
</script>

<style scoped>
.card-img {
  height: 200px !important;
}
</style>
